<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Valijas</h1>
    </div>
    <br />

    <div class="d-flex flex-wrap">
      <div v-if="$auth.can('valija', 'solicitudes_listar')" class="col-md-4">
          <div class="bpb-card">
            <div class="card-body">
              <div class="text-center">
                <img src="https://static.bienparabien.com/constelacion/img/fideicomiso.png" alt="Notarios" width="80px" />
              </div>
              <h4 class="card-title">Solicitudes</h4>
              <p class="card-subtitle text-muted">Administracion de las solicitudes</p>
              <router-link :to="{name: 'valija_solicitudes'}" class="btn complementary-btn">Acceder</router-link>
            </div>
          </div>
      </div>

      <div v-if="$auth.can('valija', 'configuracion_listar')" class="col-md-4">
        <div class="bpb-card">
          <div class="card-body">
            <div class="text-center">
              <img src="https://static.bienparabien.com/constelacion/img/fideicomiso.png" alt="Notarios" width="80px" />
            </div>
            <h4 class="card-title">Configuración</h4>
            <p class="card-subtitle text-muted">Administracion de la configuración</p>
            <router-link :to="{name: 'valija_configuracion'}" class="btn complementary-btn">Acceder</router-link>
          </div>
        </div>
      </div>

      <div v-if="$auth.can('valija', 'catalogos_listar')" class="col-md-4">
        <div class="bpb-card">
          <div class="card-body">
            <div class="text-center">
              <img src="https://static.bienparabien.com/constelacion/img/fideicomiso.png" alt="Notarios" width="80px" />
            </div>
            <h4 class="card-title">Catálogos</h4>
            <p class="card-subtitle text-muted">Administracion de los catálogos</p>
            <router-link :to="{name: 'valija_catalogos'}" class="btn complementary-btn">Acceder</router-link>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script type="text/javascript">
  export default {
    mounted: function() {
      let menu = {title: 'Valija'};

      menu.valija = 'Inicio';
      
      if (this.$auth.can('valija', 'solicitudes_listar'))
        menu.valija_solicitudes = 'Solicitudes';

      if (this.$auth.can('valija', 'configuracion_listar'))
        menu.valija_configuracion = 'Configuración';

      if (this.$auth.can('valija', 'catalogos_listar'))
        menu.valija_catalogos = 'Catálogos';

      this.$store.commit('setMenu',[menu]);
    }
  }
</script>